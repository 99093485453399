<template>
  <BaseCard
    :link="link"
    :component-type="componentType"
    :target="targetProp"
    :background="blok.bg_color && blok.bg_color.value"
    :shadow="blok.shadow"
    :border="blok.border"
    :options="blok.options"
    :padding="blok.padding"
    :rounded-corners="blok.rounded_corners"
    :border-radius="blok.border_radius"
    :is-disabled="blok.is_disabled"
    v-editable="blok"
  >
    <StoryblokComponent
      v-for="childBlok in blok.overlay_labels"
      :key="childBlok._uid"
      :blok="childBlok"
    />
    <StoryblokComponent
      v-for="childBlok in blok.overlay_image"
      :key="childBlok._uid"
      :blok="childBlok"
      class="card__overlay-image"
    />
    <div class="card__panel" :class="[alignment, hoverStateClass]">
      <StoryblokComponent
        v-for="childBlok in blok.blocks"
        :key="childBlok._uid"
        :card-padding-data="cardPaddingData"
        :blok="childBlok"
      />
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import type { SbCardStoryblok, StoryLink, MultilinkStoryblok } from '@/types'

interface Props {
  blok: SbCardStoryblok
}

const props = defineProps<Props>()
const { target, cachedUrl } = useStoryblokLink(props.blok.card_as_link)

const link = computed(() => {
  return isDiv.value ? undefined : cachedUrl.value
})

const isDiv = computed(() => {
  const link = props.blok.card_as_link

  return (
    link === undefined ||
    (link as StoryLink)?.cached_url === '' ||
    ((link as StoryLink)?.linktype === 'story' &&
      (link as StoryLink)?.id === '')
  )
})

const targetProp = computed(() => {
  if (isDiv.value) {
    return undefined
  }
  if ((props.blok.card_as_link as MultilinkStoryblok)?.target) {
    return target.value
  }
  return props.blok.target === '_blank' ? '_blank' : '_self'
})
const componentType = computed(() => (isDiv.value ? 'div' : `nuxt-link`))

const cardPaddingData = computed(() => {
  return {
    mobile: props.blok.padding?.mobile || 0,
    tablet: props.blok.padding?.tablet || 0,
    desktop: props.blok.padding?.desktop || 0,
  }
})

const alignment = computed(() => {
  return `card__panel--${props.blok.item_alignment}` || ''
})

const hoverStateClass = computed(() => {
  return props.blok.link_hover_state ? `card__panel--hover-link` : null
})
</script>

<style lang="scss" scoped>
.card {
  &__panel {
    width: 100%;
    display: flex;
    flex-direction: column;

    &--left {
      align-items: flex-start;
    }

    &--center {
      align-items: center;
    }

    &--right {
      align-items: flex-end;
    }

    &--stretch {
      flex-grow: 1;
    }
    &--hover-link:hover {
      :deep(*) {
        color: var(--color-link);
      }
    }
  }
  &__overlay-image {
    display: none;
    @media (min-width: $breakpoint-md) {
      display: block;
      position: absolute;
      top: -55px;
      right: -80px;
    }
    @media (min-width: $breakpoint-lg) {
      top: -70px;
      right: -150px;
    }
  }
  &:has(.card__overlay-image) {
    @media (min-width: $breakpoint-md) {
      overflow: visible;
    }
  }
}
</style>
